<template>
  <div>
    <AlterFormLayout
      modalId="quota-statement-modal"
      formTitle="Visualização do extrato de cota"
      cancelButtonText="Fechar"
      submitButtonText="Baixar extrato em PDF"
      :onSubmitEvent="downloadPdf"
      :disableValidation="true"
    >
      <template #content>
        <pdf-viewer :getDocumentHandler="getDocumentHandler" />
      </template>
    </AlterFormLayout>
  </div>
</template>

<script>
import PdfViewer from "../../../../components/pdf-viewer/PdfViewer.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import { downloadBase64File } from "../../../../services/pdf-download/pdf-download-services";
import { DocumentType } from "../../../../static-data/DocumentType.js";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";

export default {
  props: ["competencyDate"],
  data() {
    return {
      downloadedFile: null,
      fileUrl: null,
    };
  },
  components: {
    AlterFormLayout,
    PdfViewer,
  },
  methods: {
    downloadPdf() {
      if (!this.downloadedFile) return;
      anchorBase64Download(
        this.$store.getters.isApp,
        "application/pdf",
        "extrato-cota.pdf",
        this.downloadedFile
      );
    },
    async getDocumentHandler() {
      const download = await this.downloadFile();
      this.downloadedFile = download;
      const result = atob(download);
      return result;
    },
    async downloadFile() {
      try {
        const competency = this.$props.competencyDate;
        const response = await downloadBase64File(
          this.$store.getters.getUserName,
          DocumentType.EXTRACT,
          competency.year,
          competency.month,
          competency.day
        );

        this.fileUrl = response.fileUrl;
        return response.document;
      } catch (error) {
        console.error("Error on function downloadFile:", error);
      }
    },
  },
};
</script>
